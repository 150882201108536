import React, { useState, useEffect } from 'react';

import {floors, roomCategories} from '../constants/roomAttributes';

import {logError, checkError} from '../utils/helpers';
import {fetch, store} from '../utils/httpUtil';

import "../css/form.css";   

import {
    Alert,
    Button,
    Form, FormGroup,
    Row,
    Col,
    Label,
    Input
  } from "reactstrap";


function RoomDetails({rId, onSave}) {

    const [roomId, setRoomId] = useState(rId);   

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();

    const [roomDetails, setRoomDetails] = useState({
        roomCategoryId: null,
        roomNo: null,
        floorNo: null,
        roomDonation: null,
        hasAC: null,
        hasCooler: null,
        hasSolarGeyser: null,
        hasIndianToilet: null,
        hasWesternToilet: null,
        isAvailable: null,
        notes: null
    });

    useEffect(() => {         
        fetchRoomDetails(); 
    }, []); 

    function fetchRoomDetails(){
        if (roomId != null){
            fetch("apiRooms/" + roomId + "?type=1")
            .then((response) => {
                return checkError(response);
            })
            .then((result) => {
                setRoomDetails({ 
                    roomCategoryId: result[0].room_category_id,
                    roomNo: result[0].room_no,
                    floorNo: result[0].floor_no,
                    roomDonation: result[0].room_rent,
                    hasAC: result[0].has_AC,
                    hasCooler: result[0].has_cooler,
                    hasSolarGeyser: result[0].has_solar_geyser,
                    hasIndianToilet: result[0].has_indian_toilet,
                    hasWesternToilet: result[0].has_western_toilet,
                    isAvailable: result[0].is_available,
                    notes: result[0].notes
                });
            })
            .catch((error) => {
                //notify.show('Oops! Something went wrong! Please try again!', 'error');
                logError(constructor.name + " " + error);
            });
        }
    }

    function populateFloors() {
        let items = [];   
    
        for (let i = 0; i <= 3; i++) {             
             items.push(<option key={i} value={i}>{floors[i]}</option>);   
        }
        return items;
    }

    function populateRoomCategories() {
        let items = [];   
    
        for (let i = 0; i <= 14; i++) {             
             items.push(<option key={i} value={i}>{roomCategories[i]}</option>);   
        }
        return items;
    }

    function saveRoomDetails(e) {

        e.preventDefault();

        const payload = {   
            room_id: roomId,
            room_category_id: roomDetails.roomCategoryId,
            room_no: roomDetails.roomNo,
            floor_no: roomDetails.floorNo,
            room_rent: roomDetails.roomDonation,
            has_AC: roomDetails.hasAC,
            has_cooler: roomDetails.hasCooler,
            has_western_toilet: roomDetails.hasWesternToilet,
            has_indian_toilet: roomDetails.hasIndianToilet,
            has_solar_geyser: roomDetails.hasSolarGeyser,
            is_available: roomDetails.isAvailable,
            notes: roomDetails.notes
        };

        store("apiRooms/", JSON.stringify(payload))
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {   
           
            setAlertMsg("Room details updated successfully.")
            setAlertColor("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              onSave();
            }, 2000); 
        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000); 
            logError(error);
        });
    }
    
    return (  
            <div className = "divFormLayout">
                <Alert color={alertColor} isOpen={showAlert}>
                    {alertMsg}
                </Alert>
                <Form onSubmit={(e) => saveRoomDetails(e)}>
                <Row>
                        <Col md={3}>
                                <FormGroup>
                                <Label className="form-label">
                                Room Category:
                                </Label>
                                <Input type="select" required disabled className="form-control" value = {roomDetails.roomCategoryId}   
                                  onChange={(e) => setRoomDetails({ ...roomDetails, roomCategoryId: e.target.value })}>    
                                    {populateRoomCategories()}                                                  
                                </Input>
                                </FormGroup>
                        </Col>
        
                        <Col md={3}>
                                <FormGroup>
                                <Label className="form-label">
                                Room No:
                                </Label>
                                <Input type="text" required className="form-control" value={roomDetails.roomNo} 
                                    onChange={(e) => setRoomDetails({ ...roomDetails, roomNo: e.target.value })}>                                                    
                                </Input>
                                </FormGroup>
                        </Col>
                        <Col md={3}>
                                <FormGroup>
                                <Label className="form-label">
                                Floor No:
                                </Label>
                                <Input type="select" required className="form-control" value = {roomDetails.floorNo} 
                                  onChange={(e) => setRoomDetails({ ...roomDetails, floorNo: e.target.value })}>    
                                    {populateFloors()}                                                  
                                </Input>
                                </FormGroup>
                        </Col>

                        <Col md={3}>
                                <FormGroup>
                                <Label className="form-label">
                                Donation in &#8377;: 
                                </Label>
                                <Input type="number" required className="form-control" value = {roomDetails.roomDonation} 
                                    onChange={(e) => setRoomDetails({ ...roomDetails, roomDonation: e.target.value })}>                                                    
                                </Input>
                                </FormGroup>
                        </Col>
                </Row>  
                <Row>
                        <Col md={2}>
                                <FormGroup check>  
                                <Input type="checkbox" className="form-control" checked={roomDetails.hasAC} onChange={(e) => setRoomDetails({ ...roomDetails, hasAC: e.target.checked })} /> 
                                    {' '}
                                <Label check>
                                    Has AC?
                                </Label>
                                </FormGroup>
                        </Col>
                        <Col md={2}>
                                <FormGroup check>
                                <Input type="checkbox" className="form-control" checked={roomDetails.hasCooler} onChange={(e) => setRoomDetails({ ...roomDetails, hasCooler: e.target.checked })} /> 
                                    {' '}
                                <Label check>
                                    Has Cooler?
                                </Label>
                                </FormGroup>
                        </Col>
                        <Col md={2}>
                                <FormGroup check>
                                <Input type="checkbox" className="form-control" checked={roomDetails.hasSolarGeyser} onChange={(e) => setRoomDetails({ ...roomDetails, hasSolarGeyser: e.target.checked })} />   
                                    {' '}
                                <Label check>
                                    Has Solar Geyser?
                                </Label>
                                </FormGroup>
                        </Col>
              
                        <Col md={2}>    
                                <FormGroup check>   
                                <Input type="checkbox" className="form-control" checked={roomDetails.hasIndianToilet} onChange={(e) => setRoomDetails({ ...roomDetails, hasIndianToilet: e.target.checked })} /> 
                                    {' '}   
                                <Label check>
                                    Has Indian Toilet?  
                                </Label>
                                </FormGroup>
                        </Col>
                        <Col md={2}>    
                                <FormGroup check>
                                <Input type="checkbox" className="form-control" checked={roomDetails.hasWesternToilet} onChange={(e) => setRoomDetails({ ...roomDetails, hasWesternToilet: e.target.checked })} />   
                                    {' '}   
                                <Label check>
                                    Has Western Toilet? 
                                </Label>
                                </FormGroup>
                        </Col>
                        <Col md={2}>    
                                <FormGroup check>
                                <Input type="checkbox" className="form-control" checked={roomDetails.isAvailable} onChange={(e) => setRoomDetails({ ...roomDetails, isAvailable: e.target.checked })} /> 
                                    {' '}   
                                <Label check>
                                    Is Available?
                                </Label>
                                </FormGroup>
                        </Col>
                </Row>  
                <br/>
                <Row>
                        <Col md={6}>
                                <FormGroup>
                                <Label className="form-label">
                                    Notes:
                                </Label>
                                <Input type="textarea" className="form-control" value = {roomDetails.notes}
                                    onChange={(e) => setRoomDetails({ ...roomDetails, notes: e.target.value })}>    
                                </Input>
                                </FormGroup>
                        </Col>
                </Row>

                <div className="save-button-container">
                    <button className="btnBig" type="submit">Save</button>
                </div>
                </Form>
            </div>
            
    );
}

export default RoomDetails;